import { useLocation } from "react-router-dom";
import {
  ArticleIcon,
  BarcodeIcon,
  Check,
  CreditCardIcon,
  Flag,
  PixIcon,
} from "src/constants/Icons";

const Divider: React.FC = () => (
  <div className="inline-flex w-full items-center gap-2 self-stretch py-2">
    <div className="h-[0px] shrink grow basis-0 border border-color-neutral-base-2 bg-color-neutral-base-2"></div>
  </div>
);
const IconBackground: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div
    className={`flex size-8 items-center justify-center rounded-full bg-color-neutral-base-2 ${className}`}
  >
    {children}
  </div>
);
const LayoutCardStep: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="flex justify-center rounded-2xl border border-black/0 bg-color-neutral-soft object-center p-6 shadow md:min-w-[612px]">
    <div className="grid w-64 grid-cols-5 place-items-center">{children}</div>
  </div>
);

const CheckedStep: React.FC = () => (
  <IconBackground className="bg-color-primary-base">
    <Check className="size-5" />
  </IconBackground>
);
const ActualArticleStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <ArticleIcon className="size-5" style={{ color: "white" }} />
  </IconBackground>
);
const ActualBankSlipStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <BarcodeIcon className="size-5" style={{ color: "white" }} />
  </IconBackground>
);
const ActualPixStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <PixIcon className="size-5" style={{ color: "white" }} />
  </IconBackground>
);
const ActualCreditCardStep: React.FC = () => (
  <IconBackground className="bg-color-neutral-base-5">
    <CreditCardIcon className="size-5 text-color-neutral-soft" />
  </IconBackground>
);
const FlagCreditCardStep: React.FC = () => (
  <IconBackground>
    <Flag className="size-5" />
  </IconBackground>
);

const LockCreditCardStep: React.FC = () => (
  <IconBackground>
    <CreditCardIcon className="size-5 text-color-neutral-base-3" />
  </IconBackground>
);

const PaymentMethodsTimeline: React.FC = () => {
  return (
    <LayoutCardStep>
      <ActualArticleStep />
      <Divider />
      <LockCreditCardStep />
      <Divider />
      <FlagCreditCardStep />
    </LayoutCardStep>
  );
};

const CreditCardTimeline: React.FC = () => {
  return (
    <LayoutCardStep>
      <CheckedStep />
      <Divider />
      <ActualCreditCardStep />
      <Divider />
      <FlagCreditCardStep />
    </LayoutCardStep>
  );
};
const SucessTimeline: React.FC = () => {
  return (
    <LayoutCardStep>
      <CheckedStep />
      <Divider />
      <CheckedStep />
      <Divider />
      <CheckedStep />
    </LayoutCardStep>
  );
};
const PixTimeline: React.FC = () => {
  return (
    <LayoutCardStep>
      <CheckedStep />
      <Divider />
      <ActualPixStep />
      <Divider />
      <FlagCreditCardStep />
    </LayoutCardStep>
  );
};
const BankSlioTimeline: React.FC = () => {
  return (
    <LayoutCardStep>
      <CheckedStep />
      <Divider />
      <ActualBankSlipStep />
      <Divider />
      <FlagCreditCardStep />
    </LayoutCardStep>
  );
};

const PaymentTimeline: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;

  if (path.includes("Success")) return <SucessTimeline />;
  if (path.includes("PaymentMethods")) return <PaymentMethodsTimeline />;
  if (path.includes("CreditCard")) return <CreditCardTimeline />;
  if (path.includes("Pix")) return <PixTimeline />;
  if (path.includes("BankSlip")) return <BankSlioTimeline />;

  return null;
};

export default PaymentTimeline;
