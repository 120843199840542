import { OrderSummaryCard } from "src/components";

import OrderCard from "./OrderCard";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { useParams } from "react-router-dom";
import useNavigateOnConfirmedPayment from "src/hooks/useNavigateOnConfirmedPayment";

const Order: React.FC = () => {
  const data = useCheckoutDataContext();
  const params = useParams<OrderSubscriptionParamsType>();
  const token = params.token as string;

  useNavigateOnConfirmedPayment(data, token);

  return (
    <main className="h-full bg-color-neutral-base-2 p-4">
      <div className="grid w-full gap-4 rounded-lg py-6 md:grid-cols-[auto_auto] md:items-start lg:justify-center">
        <OrderCard />
        <OrderSummaryCard order={data} />
      </div>
    </main>
  );
};

export default Order;
