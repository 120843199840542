import React from "react";

import { KeyValues } from "src/constants";

type SelectPaymentMethodsProps = {
  data: AllowedPaymentMethodsType[];
  selectedMethod: AllowedPaymentMethodsType;
  handlePaymentMethodChange: (method: AllowedPaymentMethodsType) => void;
};

const SelectPaymentMethods: React.FC<SelectPaymentMethodsProps> = ({
  data,
  selectedMethod,
  handlePaymentMethodChange,
}) => {
  const { PaymentMethodKeys } = KeyValues;

  return (
    <div
      role="radiogroup"
      className="h-500px flex flex-col justify-center gap-2 self-stretch rounded-lg"
    >
      <div className="flex flex-col gap-4">
        {data.map((method) => {
          const paymentMethodData = PaymentMethodKeys.find(
            (paymentMethod) => paymentMethod.type === method,
          );

          if (!paymentMethodData) {
            return null; // Return null to avoid rendering undefined keys
          }
          const selectCustomClass =
            selectedMethod === method
              ? "border-color-primary-base bg-red-50"
              : "border-color-neutral-base-2";
          return (
            <div
              key={method}
              className={`flex cursor-pointer items-center justify-between rounded-lg border p-4 ${selectCustomClass}`}
              onClick={() => handlePaymentMethodChange(method)}
            >
              <div className="flex items-center justify-center gap-4">
                <img
                  src={paymentMethodData.icon}
                  alt={`${method} icon`}
                  className="h-6 w-6 text-slate-50"
                />
                <div className="flex-col items-start justify-center gap-4">
                  <span>{paymentMethodData.label}</span>

                  {paymentMethodData.description && (
                    <div>
                      <span className="text-sm font-normal leading-[21px] text-color-neutral-base-4">
                        {paymentMethodData.description}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <input
                type="radio"
                name="paymentMethod"
                aria-label={paymentMethodData.label}
                value={method}
                checked={selectedMethod === method}
                onChange={() => handlePaymentMethodChange(method)}
                className="h-5 w-5 text-color-primary-base"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectPaymentMethods;
