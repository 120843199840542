import React from "react";
import { Strings } from "src/constants";
import { CheckCircle } from "src/constants/Icons";
import LayoutCard from "src/components/shared/LayoutCard";
import PaymentTimeline from "src/components/shared/PaymentTimeline";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { PaymentSummaryCard } from "src/components";

const BankSlipPaymentSucess: React.FC = () => {
  const paymentInfo = useCheckoutDataContext();

  return (
    <main className="flex grow flex-col items-center bg-color-neutral-base-2">
      <div className="grid max-w-screen-xl items-start gap-4 rounded-lg p-4 md:grid-flow-col md:grid-cols-[auto_1fr] md:grid-rows-[auto_1fr] lg:px-6">
        <PaymentTimeline />
        <LayoutCard>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-color-neutral-strong text-2xl font-semibold leading-[20px]">
              {Strings.CREDIT_CARD_PAYMENT_SUCESS_TITLE}
            </div>
            <p className="items-start">
              {Strings.CREDIT_CARD_PAYMENT_SUCESS_SUBTITLE}
            </p>
          </div>
          <div className="grid grid-cols-[auto_1fr] items-center gap-x-4 rounded-lg bg-color-status-success-bg p-4">
            <CheckCircle className="row-span-2" />
            <p className="items-start font-bold text-color-status-success-base">
              {Strings.CREDIT_CARD_PAYMENT_SUCESS_MESSAGE_TITLE}
            </p>
            <p className="items-start text-color-status-success-base">
              {Strings.CREDIT_CARD_PAYMENT_SUCESS_MESSAGE_SUBTITLE +
                " " +
                paymentInfo.customer?.email}
            </p>
          </div>
        </LayoutCard>
        <div className="md:row-span-2">
          <PaymentSummaryCard order={paymentInfo} />
        </div>
      </div>
    </main>
  );
};

export default BankSlipPaymentSucess;
