import React from "react";

import { Icons, Strings } from "src/constants";
const Footer: React.FC = () => {
  return (
    <footer className="flex justify-center bg-color-neutral-base-2 text-center text-color-neutral-base-4">
      <div className="flex h-auto max-w-[1100px] shrink grow basis-0 flex-wrap items-center justify-center gap-4 p-6 lg:px-0">
        <img src={Icons.Pay2b} alt="Pay2b" />
        <div className="flex h-[29px] shrink grow basis-0 items-center justify-end gap-2">
          <div className="flex items-center justify-center gap-2 rounded-full bg-black/0 px-4 py-1">
            <div className="text-sm font-normal leading-[21px] text-color-neutral-base-4">
              {Strings.PRIVACY_POLICY}
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 rounded-full bg-black/0 px-4 py-1">
            <div className="text-sm font-normal leading-[21px] text-color-neutral-base-4">
              {Strings.HELP}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
