import axios, { AxiosError } from 'axios';

import { ApiHelper } from 'src/utils';

import { KeyValues } from 'src/constants';

import env from '../env.json';

const { baseURL } = env;
const { ApiHeaderKeys } = KeyValues;

const headers = {
  [ApiHeaderKeys.ACCEPT_KEY]: ApiHeaderKeys.ACCEPT_VALUE,
  [ApiHeaderKeys.CONTENT_TYPE_KEY]: ApiHeaderKeys.CONTENT_TYPE_VALUE,
};

export const api = axios.create({
  baseURL,
  headers,
  timeout: 1000 * 30, // 10 seconds
  validateStatus: (status: number) => ApiHelper.validateStatus(status),
});

const handleError = (error: AxiosError) => {
  return Promise.reject(ApiHelper.handleError(error));
};

api.interceptors.request.use(ApiHelper.interceptorRequest, handleError);
api.interceptors.response.use(ApiHelper.interceptorResponse, handleError);
