import React, { useEffect, useMemo } from "react";

import { toast } from "react-toastify";

import { PaymentSummaryCard, SecondaryButton } from "src/components";
import LayoutCard from "src/components/shared/LayoutCard";
import { Strings } from "src/constants";

import { Copy, Timer } from "src/constants/Icons";
import PageNotFound from "../PageNotFound";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { TimerComponent } from "./TimerComponent";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { checkout } from "src/services";

const Pix: React.FC = () => {
  const data = useCheckoutDataContext();
  const navigate = useNavigate();
  const params = useParams<OrderSubscriptionParamsType>();

  const token = params.token as string;

  const queryClient = useQueryClient();

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const data = await checkout(token);
        const isConfirmed = data.status === "CONFIRMED";
        if (isConfirmed) {
          queryClient.invalidateQueries({
            queryKey: ["checkout"],
          });

          navigate(`/${token}/Pix/Success`);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const intervalId = setInterval(verifyPayment, 15 * 1000);
    return () => {
      clearTimeout(intervalId);
    };
  }, [queryClient, navigate, token]);

  const handleCopyToClipboard = () => {
    if (!qrCodeText) return;
    navigator.clipboard.writeText(qrCodeText);
    toast.success(Strings.COPIED_TO_CLIPBOARD);
  };

  const qrCodeText = useMemo(() => {
    const paymentInstruments =
      data?.paymentInstruments as PixCheckoutResponse["paymentInstruments"];
    return paymentInstruments?.[0]?.qrcode?.text;
  }, [data]);

  const originalUrl = useMemo(() => {
    const paymentInstruments =
      data?.paymentInstruments as PixCheckoutResponse["paymentInstruments"];
    return paymentInstruments?.[0]?.qrcode?.image;
  }, [data]);

  console.log(originalUrl);
  if (!data) return <PageNotFound />;

  return (
    <main className="flex grow flex-col items-center bg-color-neutral-base-2">
      <div className="grid max-w-screen-xl items-start gap-4 rounded-lg p-4 md:grid-cols-[auto_1fr] lg:px-6">
        <LayoutCard>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-color-neutral-strong text-2xl font-semibold leading-[20px]">
              {Strings.PIX}
            </div>
          </div>
          <div className="grid grid-cols-[auto_1fr] items-center gap-x-4 rounded-lg bg-color-status-info-base p-4">
            <Timer className="row-span-2" />
            <TimerComponent />
            <p className="items-start text-color-status-info-text">
              {Strings.PIX_INFO_MESSAGE}
            </p>
          </div>
          <div className="grid gap-2 md:grid-cols-[auto_1fr]">
            <p className="items-start md:col-span-2">
              {Strings.PIX_QRCODE_INFO_MESSAGE}
            </p>
            <img
              src={originalUrl}
              alt="qrcode"
              crossOrigin="anonymous"
              className="hidden md:block"
            />
            <div className="items-center gap-2 rounded-lg border border-[#C1C5CA] bg-color-neutral-base-2 p-4">
              <p className="items-start font-light">{qrCodeText}</p>
            </div>
          </div>
          <div className="flex justify-center">
            <SecondaryButton
              text={Strings.BANK_SLIP_COPY_CODE_TEXT}
              Icon={Copy}
              onClick={handleCopyToClipboard}
              position="left"
              variant="secondary"
              className="text-sm"
              size="small"
            />
          </div>
        </LayoutCard>
        <PaymentSummaryCard order={data} />
      </div>
    </main>
  );
};

export default Pix;
