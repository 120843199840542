import React from "react";
import { Strings } from "src/constants";
import { useLocation } from "react-router-dom";
import {
  Divider,
  PaymentMethodInfo,
  ProductInfo,
  SummaryHeader,
  TotalAmount,
} from "src/components";

type OrderSummaryCardProps = {
  order?: CheckoutType;
};

// Main component
const PaymentSummaryCard: React.FC<OrderSummaryCardProps> = ({ order }) => {
  const pathname = useLocation().pathname;
  const isCreditCardRoute = pathname.includes("CreditCard");
  const totalAmount = order?.checkoutPreferences?.totalAmount ?? 0;
  const paymentMethod = order?.paymentInstruments?.[0]?.method as string;
  const isCrediCardMethod =
    order?.paymentInstruments?.[0]?.method === "CREDIT_CARD";

  return (
    <div className="inline-flex w-full flex-col items-center justify-center gap-8 rounded-2xl border border-black/0 bg-color-neutral-soft p-8 shadow lg:w-[400px]">
      <SummaryHeader summaryText={Strings.SUMMARY} />
      <div className="flex h-auto flex-col items-center justify-center self-stretch">
        <ProductInfo
          productTitle={Strings.PRODUCT}
          description={order?.insurance?.type?.description}
        />
        {!isCreditCardRoute && (
          <>
            <Divider />
            <PaymentMethodInfo
              method={paymentMethod}
              amount={totalAmount ?? 0}
            />
          </>
        )}
        <Divider />
        <TotalAmount total={totalAmount} />
        {isCrediCardMethod && (
          <PaymentMethodInfo method={paymentMethod} amount={totalAmount ?? 0} />
        )}
      </div>
    </div>
  );
};

export default PaymentSummaryCard;
