import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useNavigateOnConfirmedPayment = (data: CheckoutType, token: string) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!data) return;

        const paymentMethod = data.paymentInstruments?.[0]?.method;
        const status = data.status;

        if (status === "WAITING_PAYMENT_PIX") return navigate(`/${token}/Pix`);
        if (status === "WAITING_PAYMENT_BANK_SLIP") return navigate(`/${token}/BankSlip`);


        if (data.status !== "CONFIRMED") return;
        switch (paymentMethod) {
            case "PIX":
                navigate(`/${token}/Pix/Success`);
                break;
            case "CREDIT_CARD":
                navigate(`/${token}/CreditCard/Success`);
                break;
            case "BANK_SLIP":
                navigate(`/${token}/BankSlip/Success`);
                break;
            default:
                break;
        }
    }, [data, navigate, token]);
};

export default useNavigateOnConfirmedPayment;
