export const Strings = {
  LOADING: 'Carregando ... aguarde!',
  SOMETHING_WENT_WRONG: 'Aconteceu algo de errado',
  NO_INTERNET_CONNECTION: 'Você está sem conexão com a internet',
  REQUIRED: 'O campo é obrigatório',
  EMPTY: '',
  PAGE_NOT_FOUND: 'Página não encontrada',
  PRIVACY_POLICY: 'Política de privacidade',
  HELP: 'Ajuda',
  CAR_INSURANCE: 'Seguro auto',
  CREDIT_CARD_PAYMENT_SUCESS_TITLE: 'Tudo certo!',
  CREDIT_CARD_PAYMENT_SUCESS_SUBTITLE: 'Você concluiu o processo de pagamento',
  CREDIT_CARD_PAYMENT_SUCESS_MESSAGE_TITLE: 'O pagamento foi realizado',
  CREDIT_CARD_PAYMENT_SUCESS_MESSAGE_SUBTITLE: 'Você receberá a confirmação no email',
  PIX_INFO_MESSAGE: 'Por favor, conclua o pagamento antes do tempo finalizar',
  PIX_QRCODE_INFO_MESSAGE: 'Utilize o código abaixo no app do seu banco:',
  PIX_COPY_CODE_TEXT: 'Copiar Código Pix',
  BANK_SLIP_COPY_CODE_TEXT: 'Copiar Código ',
  COPIED_TO_CLIPBOARD: 'Copiado para a área de transferência',
  TITULAR: 'Titular',
  CPF: 'CPF',
  PAYMENT: 'Pagamento',
  PROPOSAL_NUMBER: 'Número da proposta',
  SUMMARY: 'Resumo',
  PRODUCT: 'Produto',
  CONTRACTED_VALUE: 'Valor contratado',
  BROKER: 'Corretora',
  VEHICLE_LICENSE_PLATE: 'Placa do Veículo',
  BR_CURRENCY_SYMBOL: 'R$',
  START_PAYMENT: 'Começar o pagamento',
  SELECT_A_PAYMENT_METHOD: 'Selecione um meio de pagamento',
  CONTINUE: 'Continuar',
  LOYALTY_POINTS: 'Pontos',
  CREDIT_CARD: 'Cartão de Crédito',
  BALANCE: 'Saldo',
  PIX: 'Pix',
  DEBIT: 'Débito',
  NUPAY: 'NUPAY',
  CASH_PAYMENT: 'Pagamento à vista',
  OPEN_FINANCE: 'Open Finance',
  BANK_SLIP: 'Boleto',
  BANK_SLIP_SUCESS_TITLE: 'Boleto gerado com sucesso!',
  BANK_SLIP_SUCESS_SUBTITLE: 'O processamento do pagamento pode levar até 3 dias úteis',
  BANK_SLIP_SUCESS_SUBTITLE_2: 'Você receberá a confirmação do pagamento por e-mail.',
  BANK_SLIP_INFO_MESSAGE: 'Aqui está seu boleto',
  CREDIT_CARD_NUMBER: 'Número do cartão',
  FULL_NAME: 'Nome completo',
  USE_THE_SAME_NAME: 'Utilize o mesmo nome impresso no cartão',
  EXPIRATION_DATE: 'Data de vencimento',
  SECURITY_CODE: 'Cód. de segurança',
  SECURITY_CODE_PLACEHOLDER: '000',
  CPF_HOLDER: 'CPF do titular',
  DATE_OF_BIRTH: 'Data de nascimento',
  DATE_OF_BIRTH_PLACEHOLDER: 'DD/MM/AAAA',
  NUMBER_OF_PLOTS: 'Número de parcelas',
  MAKE_PAYMENT: 'Realizar pagamento',
  NUMBER_PLACEHOLDER: '0000 0000 0000 0000',
  EXPIRATION_DATE_PLACEHOLDER: 'MM/AAAA',
  INVALID_CARD_NUMBER: 'O cartão é inválido',
  INVALID_DOCUMENT: 'CPF inválido',
  INVALID_EXPIRATION_DATE: 'Data de vencimento inválido',
  INVALID_HOLDER: 'Nome inválido',
  INVALID_SECURITY_CODE: 'Cód. de segurança inválido',
  INVALID_DATE_OF_BIRTH: 'Data de nascimento inválido',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  AMEX: 'AMEX',
  DISCOVER: 'DISCOVER',
  DINERS: 'DINERS',
};

export default Strings;
