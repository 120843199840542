import React from "react";
import ButtonSpinner from "../ButtonSpinner";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
type ButtonProps = {
  text: string;
  icon?: string;
  isLoading?: boolean;
  position?: "left" | "right";
  variant?: "primary" | "secondary";
  className?: string;
  size?: "small" | "medium" | "large";
  onClick: () => void;
};

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  position = "right",
  isLoading = false,
  variant = "primary",
  className = "",
  size = "medium",
  onClick,
}) => {
  const data = useCheckoutDataContext();
  const buttonConfirmBackground =
    data.visualPreferences.layout.colors.buttonConfirmBackground;

  const buttonConfirmText =
    data.visualPreferences.layout.colors.buttonConfirmText;
  return (
    <button
      className={`inline-flex w-fit items-center gap-2 rounded-full px-5 py-2 text-base font-normal leading-normal text-color-neutral-soft ${variant === "primary" ? "bg-color-primary-base text-color-neutral-soft" : "border border-color-neutral-base-3 bg-color-neutral-soft text-color-primary-base"} text-base font-normal leading-normal ${className}`}
      onClick={onClick}
      style={{
        backgroundColor: buttonConfirmBackground,
        color: buttonConfirmText,
      }}
    >
      {isLoading ? (
        <div className="relative w-1/2">
          <ButtonSpinner width="24px" height="24px" />
        </div>
      ) : (
        <div
          className={`inline-flex items-center gap-2 ${position === "left" ? "flex-row-reverse" : "flex-row"}`}
        >
          <span>{text}</span>
          {icon && (
            <div
              className={`relative h-6 w-6 ${size === "small" ? "h-4 w-4" : "h-6 w-6"}`}
            >
              <img src={icon} alt="" style={{ color: buttonConfirmText }} />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
