import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Pix = lazy(() => import("./pages/Pix"));
const Order = lazy(() => import("./pages/Order"));
const Dummy = lazy(() => import("./pages/Dummy"));
const BankSlip = lazy(() => import("./pages/BankSlip"));
const CreditCard = lazy(() => import("./pages/CreditCard"));
const CreditCardSuccess = lazy(() => import("./pages/CreditCardSuccess"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const PaymentMethods = lazy(() => import("./pages/PaymentMethods"));

import { Footer, Header, Spinner } from "./components";
import { SharedDataProvider } from "./providers/SharedDataProvider";
import { BankSlipPaymentSucess, PixPaymentSuccess } from "./pages";

const queryClient = new QueryClient();

const AppLayout: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Routes>
        <Route path="Dummy" element={<Dummy />} />
        <Route path="" element={<Order />} />
        <Route path="Pix" element={<Pix />} />
        <Route path="BankSlip" element={<BankSlip />} />
        <Route path="CreditCard" element={<CreditCard />} />
        <Route path="CreditCard/Success" element={<CreditCardSuccess />} />
        <Route path="Pix/Success" element={<PixPaymentSuccess />} />
        <Route path="BankSlip/Success" element={<BankSlipPaymentSucess />} />
        <Route path="PaymentMethods" element={<PaymentMethods />} />
      </Routes>
      <Footer />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Spinner />}>
        <ToastContainer autoClose={8000} />
        <BrowserRouter>
          <Routes>
            <Route
              path=":token/*"
              element={
                <SharedDataProvider>
                  <AppLayout />
                </SharedDataProvider>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
