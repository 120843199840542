import { toast } from "react-toastify";
import React, { ChangeEvent, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { pay, tokenizer } from "src/services";

import { CreditCardHelper } from "src/utils";

import CreditCardWarning from "./CreditCardWarning";
import CreditCardValidation from "./CreditCardValidation";

import { Icons, KeyValues, Strings } from "src/constants";

import {
  Button,
  InputText,
  OrderSummaryCard,
  SelectInstallments,
} from "src/components";
import PaymentTimeline from "src/components/shared/PaymentTimeline";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import env from "src/env.json";

const CreditCard: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<OrderSubscriptionParamsType>();

  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const token = params.token as string;
  const { CreditCardKeys } = KeyValues;
  const order = useCheckoutDataContext();
  const installmentCount = order.checkoutPreferences.installmentCount;
  const isHomologEnvironment = env.environment === "hml";
  const testCardValue = isHomologEnvironment
    ? {
        [CreditCardKeys.CARD_NUMBER]: "4000 0000 0000 0010",
        [CreditCardKeys.HOLDER]: "CARLOS ROSA",
        [CreditCardKeys.EXPIRATION_DATE]: "12/2025",
        [CreditCardKeys.SECURITY_CODE]: "111",
        [CreditCardKeys.DOCUMENT]: "289.923.898-17",
        [CreditCardKeys.DATE_OF_BIRTH]: "14/05/1981",
        [CreditCardKeys.INSTALLMENTS]:
          order.checkoutPreferences.installmentCount,
      }
    : {};
  console.log(testCardValue);

  const fields = {
    [CreditCardKeys.CARD_NUMBER]: Strings.EMPTY,
    [CreditCardKeys.HOLDER]: Strings.EMPTY,
    [CreditCardKeys.EXPIRATION_DATE]: Strings.EMPTY,
    [CreditCardKeys.SECURITY_CODE]: Strings.EMPTY,
    [CreditCardKeys.DOCUMENT]: Strings.EMPTY,
    [CreditCardKeys.DATE_OF_BIRTH]: Strings.EMPTY,
    [CreditCardKeys.INSTALLMENTS]: Strings.EMPTY,
  };
  const [inputs, setInputs] = useState({
    ...fields,
    ...testCardValue,
  });
  const [errors, setErrors] = useState(fields);

  const handleChange = (value: string, inputName: string) => {
    const newValue = CreditCardHelper.mask(value, inputName);
    setInputs((prevState) => ({
      ...prevState,
      [inputName]: newValue,
    }));
  };

  const handleError = (error: string, inputName: string) => {
    setErrors((prevState) => ({
      ...prevState,
      [inputName]: error,
    }));
  };

  const handlePay = async (id: string) => {
    setPaymentLoading(true);
    const requestPay = {
      paymentInstruments: [
        {
          id,
          type: "CREDIT_CARD",
        },
      ],
    };
    pay(token, requestPay)
      .then((response) => {
        navigate(`/${token}/CreditCard/Success`, {
          state: {
            paymentInfo: response,
          },
        });
      })
      .catch((error) => toast.error(error.case))
      .finally(() => setPaymentLoading(false));
  };

  const handleContinue = async () => {
    const isValidForm = CreditCardValidation({ inputs, handleError });
    setHasErrors(!isValidForm);

    const name = inputs[CreditCardKeys.HOLDER] as string;
    const taxId = CreditCardHelper.onlyNumbers(
      inputs[CreditCardKeys.DOCUMENT] as string,
    );
    const pan = CreditCardHelper.onlyNumbers(
      inputs[CreditCardKeys.CARD_NUMBER] as string,
    );
    const expirationDate = inputs[CreditCardKeys.EXPIRATION_DATE] as string;
    const expMonth = CreditCardHelper.getExpirationMonth(expirationDate);
    const expYear = CreditCardHelper.getExpirationYear(expirationDate);
    const securityCode = inputs[CreditCardKeys.SECURITY_CODE] as string;
    const publicKey = order.security.publicKey;

    if (isValidForm) {
      setLoading(true);
      const creditCardData = {
        holder: {
          name,
          taxId,
        },
        pan,
        expMonth,
        expYear,
        securityCode,
        brand: CreditCardHelper.getCreditCardBrand(pan),
        store: true,
      };

      const dataEncrypted = CreditCardHelper.encrypt(creditCardData, publicKey);

      const requestTokenizer = {
        dataEncrypted,
      } as TokenizerRequestType;
      tokenizer(token, requestTokenizer)
        .then((response) => handlePay(response.id))
        .catch((error) => toast.error(error.case))
        .finally(() => setLoading(false));
    }
  };

  return (
    <main className="flex grow flex-col items-center bg-color-neutral-base-2">
      <div className="grid max-w-screen-xl grid-rows-[auto_1fr] items-start gap-4 rounded-lg p-4 md:grid-flow-col md:grid-cols-[1fr_auto] lg:px-6">
        <PaymentTimeline />
        <div className="flex-col items-start justify-center">
          <div className="flex flex-col items-center justify-center gap-8 rounded-2xl border border-black/0 bg-color-neutral-soft p-8 shadow md:w-[612px]">
            <div className="flex flex-col justify-center gap-8 self-stretch">
              <div className="flex h-auto flex-col items-start justify-center gap-2 self-stretch">
                <div className="text-color-neutral-strong text-2xl font-semibold leading-[30px]">
                  {Strings.CAR_INSURANCE}
                </div>
              </div>
              <p className="items-start text-lg">
                {Strings.SELECT_A_PAYMENT_METHOD}
              </p>

              <div className="flex flex-col gap-4">
                {hasErrors && <CreditCardWarning />}

                <InputText
                  label={Strings.CREDIT_CARD_NUMBER}
                  placeholder={Strings.NUMBER_PLACEHOLDER}
                  value={inputs[CreditCardKeys.CARD_NUMBER]}
                  error={errors[CreditCardKeys.CARD_NUMBER]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleChange(e.target.value, CreditCardKeys.CARD_NUMBER);
                  }}
                  onFocus={() => {
                    handleError(Strings.EMPTY, CreditCardKeys.CARD_NUMBER);
                  }}
                />
                <div className="flex grow">
                  <InputText
                    label={Strings.FULL_NAME}
                    placeholder={Strings.FULL_NAME}
                    value={inputs[CreditCardKeys.HOLDER]}
                    error={errors[CreditCardKeys.HOLDER]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e.target.value, CreditCardKeys.HOLDER);
                    }}
                    onFocus={() => {
                      handleError(Strings.EMPTY, CreditCardKeys.HOLDER);
                    }}
                  />
                </div>

                <div className="flex gap-4">
                  <InputText
                    label={Strings.EXPIRATION_DATE}
                    placeholder={Strings.EXPIRATION_DATE_PLACEHOLDER}
                    value={inputs[CreditCardKeys.EXPIRATION_DATE]}
                    error={errors[CreditCardKeys.EXPIRATION_DATE]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(
                        e.target.value,
                        CreditCardKeys.EXPIRATION_DATE,
                      );
                    }}
                    onFocus={() => {
                      handleError(
                        Strings.EMPTY,
                        CreditCardKeys.EXPIRATION_DATE,
                      );
                    }}
                  />

                  <InputText
                    label={Strings.SECURITY_CODE}
                    placeholder={Strings.SECURITY_CODE_PLACEHOLDER}
                    value={inputs[CreditCardKeys.SECURITY_CODE]}
                    error={errors[CreditCardKeys.SECURITY_CODE]}
                    icon={Icons.Help}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(
                        e.target.value,
                        CreditCardKeys.SECURITY_CODE,
                      );
                    }}
                    onFocus={() => {
                      handleError(Strings.EMPTY, CreditCardKeys.SECURITY_CODE);
                    }}
                  />
                </div>

                <div className="flex gap-4">
                  <InputText
                    label={Strings.CPF_HOLDER}
                    placeholder={Strings.CPF_HOLDER}
                    value={inputs[CreditCardKeys.DOCUMENT]}
                    error={errors[CreditCardKeys.DOCUMENT]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e.target.value, CreditCardKeys.DOCUMENT);
                    }}
                    onFocus={() => {
                      handleError(Strings.EMPTY, CreditCardKeys.DOCUMENT);
                    }}
                  />

                  <InputText
                    label={Strings.DATE_OF_BIRTH}
                    placeholder={Strings.DATE_OF_BIRTH_PLACEHOLDER}
                    value={inputs[CreditCardKeys.DATE_OF_BIRTH]}
                    error={errors[CreditCardKeys.DATE_OF_BIRTH]}
                    icon={Icons.Calendar}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(
                        e.target.value,
                        CreditCardKeys.DATE_OF_BIRTH,
                      );
                    }}
                    onFocus={() => {
                      handleError(Strings.EMPTY, CreditCardKeys.DATE_OF_BIRTH);
                    }}
                  />
                </div>

                <SelectInstallments
                  quantity={installmentCount}
                  value={inputs[CreditCardKeys.INSTALLMENTS] as number}
                  error={errors[CreditCardKeys.INSTALLMENTS]}
                  onChange={(e) =>
                    handleChange(e.target.value, CreditCardKeys.INSTALLMENTS)
                  }
                  onFocus={() => {
                    handleError(Strings.EMPTY, CreditCardKeys.INSTALLMENTS);
                  }}
                  disabled
                />
              </div>
            </div>
            <Button
              text={Strings.MAKE_PAYMENT}
              onClick={handleContinue}
              isLoading={loading || paymentLoading}
            />
          </div>
        </div>
        <div className="row-span-2">
          <OrderSummaryCard order={order} />
        </div>
      </div>
    </main>
  );
};

export default CreditCard;
