import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { HttpStatusCodes } from 'src/constants';

class AxiosHelper {
  readonly validateStatus = (status: number) => {
    return (
      status >= HttpStatusCodes.SUCCESS &&
      status < HttpStatusCodes.MULTIPLE_CHOICES
    );
  };

  readonly handleError = (error: AxiosError) => {
    if (error.response) {
      const data = error?.response?.data as ErrorResponse;
      return {
        statusCode: data.responseCode,
        cause: data.cause.errors.map((error) => error.message).join(', '),
        requestId: data.requestId,
      };
    } else if (error.request) {
      return {
        statusCode: 400,
        cause: {
          errors: [
            {
              error: 'NO_INTERNET_CONNECTION',
              message: 'Favor verifique sua internet.',
            },
          ],
        },
      };
    } else {
      return {
        statusCode: error.code,
        cause: {
          errors: [
            {
              error: error.code,
              message: error.cause,
            },
          ],
        },
      };
    }
  };

  readonly interceptorRequest = async (request: InternalAxiosRequestConfig) => {
    const { baseURL, data, method, url } = request;

    console.info(`-> [${method}] - ${baseURL}${url}`);
    console.info('headers:', JSON.stringify(request.headers, null, 2));
    if (data) {
      console.info(`BODY: ${JSON.stringify(data, null, 2)}`);
    }

    return request;
  };

  readonly interceptorResponse = async (response: AxiosResponse) => {
    const {
      status,
      config: { baseURL, url, method },
    } = response;

    const data = response.data;

    console.info(
      `<- [${method}] - ${baseURL}${url} - [${status}|${data?.returnCode}]`,
    );
    if (data) {
      console.info(`BODY: ${JSON.stringify(data, null, 2)}`);

    }

    return response;
  };
}

const ApiHelper = new AxiosHelper();
export default ApiHelper;
