import React from "react";

import { toast } from "react-toastify";

import { PaymentSummaryCard, SecondaryButton } from "src/components";
import LayoutCard from "src/components/shared/LayoutCard";
import { Strings } from "src/constants";
import { BarcodeIcon, Copy, Info } from "src/constants/Icons";
import { CurrencyHelper, TimeHelper } from "src/utils";
import PaymentTimeline from "src/components/shared/PaymentTimeline";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import useNavigateOnConfirmedPayment from "src/hooks/useNavigateOnConfirmedPayment";
import { useParams } from "react-router-dom";

const BankSlip: React.FC = () => {
  const data = useCheckoutDataContext();
  const params = useParams<OrderSubscriptionParamsType>();

  const token = params.token as string;

  useNavigateOnConfirmedPayment(data, token);

  const paymentInstruments =
    data?.paymentInstruments as BankSlipCheckoutResponse["paymentInstruments"];
  const barCodeImage = paymentInstruments?.[0]?.barcode.image;
  const barcode = paymentInstruments?.[0]?.barcode.formatted;
  const bankSlipValue = paymentInstruments?.[0]?.value;
  const dueDate = paymentInstruments?.[0]?.dueDate;

  const handleCopyToClipboard = () => {
    if (!barcode) return;
    navigator.clipboard.writeText(barcode);
    toast.success(Strings.COPIED_TO_CLIPBOARD);
  };

  return (
    <main className="flex grow flex-col items-center bg-color-neutral-base-2">
      <div className="grid max-w-screen-xl items-start gap-4 rounded-lg p-4 md:grid-cols-[auto_1fr] lg:px-6">
        <PaymentTimeline />
        <div className="row-span-2">
          <PaymentSummaryCard order={data} />
        </div>
        <LayoutCard>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-color-neutral-strong text-2xl font-semibold leading-[20px]">
              {Strings.BANK_SLIP}
            </div>
          </div>
          <div className="grid grid-cols-[auto_1fr] items-center gap-x-4 rounded-lg bg-color-status-info-base p-4">
            <Info className="row-span-3" />
            <p className="items-start font-bold text-color-status-info-text">
              {Strings.BANK_SLIP_SUCESS_TITLE}
            </p>
            <p className="items-start text-color-status-info-text">
              {Strings.BANK_SLIP_SUCESS_SUBTITLE}
            </p>
            <p className="items-start text-color-status-info-text">
              {Strings.BANK_SLIP_SUCESS_SUBTITLE_2}
            </p>
          </div>
          <div className="grid gap-8">
            <p className="items-start">{Strings.BANK_SLIP_INFO_MESSAGE}</p>
            <div className="flex w-full items-center justify-between gap-4 rounded-lg border p-4">
              <BarcodeIcon />
              <div className="flex flex-1 flex-col">
                <p className="text-sm text-color-neutral-base-5">
                  {" "}
                  Vencimento em {TimeHelper.formatDate(dueDate ?? "")}{" "}
                </p>
                <p>
                  <span>Boleto de</span>{" "}
                  <span>
                    {CurrencyHelper.formatBRCurrency(bankSlipValue ?? 0)}
                  </span>
                </p>
              </div>
              <SecondaryButton
                text={Strings.BANK_SLIP_COPY_CODE_TEXT}
                Icon={Copy}
                onClick={handleCopyToClipboard}
                position="left"
                variant="secondary"
                className="border-none text-sm"
                size="small"
              />
            </div>
          </div>
          <p className="text-center">{barcode}</p>
          <img src={barCodeImage} alt={barcode} crossOrigin="anonymous" />
        </LayoutCard>
      </div>
    </main>
  );
};

export default BankSlip;
