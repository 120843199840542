import { useState, useEffect, useRef } from "react";

type UseTimerOptions = {
    initialTime?: number; // Starting time in seconds
    interval?: number;    // Interval time in milliseconds (default 1000ms)
    onEnd?: () => void;   // Callback when the timer reaches zero
};

const useTimer = ({ initialTime = 0, interval = 1000, onEnd }: UseTimerOptions) => {
    const [time, setTime] = useState(initialTime);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime <= 1 && onEnd) {
                    clearInterval(timerRef.current!);
                    onEnd();
                }
                return prevTime > 0 ? prevTime - 1 : prevTime; // Decrease time
            });
        }, interval);

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [interval, onEnd]);

    return time; // Returns the current time in seconds
};

export default useTimer;
