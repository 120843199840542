import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, OrderSummaryCard, SelectPaymentMethods } from "src/components";
import LayoutCard from "src/components/shared/LayoutCard";
import PaymentTimeline from "src/components/shared/PaymentTimeline";

import { Icons, KeyValues, Strings } from "src/constants";
import useNavigateOnConfirmedPayment from "src/hooks/useNavigateOnConfirmedPayment";
import usePayOrderCheckout from "src/hooks/usePayOrderCheckout";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";

const PaymentMethods: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams<OrderSubscriptionParamsType>();

  const [selectedMethod, setSelectedMethod] =
    useState<AllowedPaymentMethodsType>("CREDIT_CARD");

  const token = params.token as string;
  const order = useCheckoutDataContext();

  const { PaymentMethodKeys } = KeyValues;

  const allowedPaymentMethods =
    order.checkoutPreferences?.allowedPaymentMethods;

  const data = useCheckoutDataContext();
  useNavigateOnConfirmedPayment(data, token);

  const { mutate: payOrderCheckout, isPending } = usePayOrderCheckout();

  const handlePaymentMethodChange = (method: AllowedPaymentMethodsType) => {
    setSelectedMethod(method);
  };

  const handleContinue = () => {
    const { route, type } =
      PaymentMethodKeys.find((method) => method.type === selectedMethod) || {};

    if (!route || !type) {
      return;
    }

    if (type === "CREDIT_CARD") {
      return navigate(`/${token}${route}`, {
        state: {
          order: { ...order, type },
        },
      });
    }
    payOrderCheckout(
      {
        token,
        request: { paymentInstruments: [{ type: type }] },
      },
      {
        onSuccess: (newData) => {
          queryClient.setQueryData(
            ["checkout", token],
            (oldData: CheckoutType) => {
              return {
                ...oldData,
                ...newData,
              };
            },
          );
          navigate(`/${token}${route}`, {
            state: {
              order: { ...order, type },
            },
          });
        },
      },
    );
  };

  return (
    <main className="flex flex-1 justify-center bg-color-neutral-base-2">
      <div className="grid w-11/12 items-start gap-4 py-8 md:justify-center md:px-6 lg:w-fit lg:grid-flow-col lg:grid-cols-[auto_1fr]">
        <div className="space-y-4">
          <PaymentTimeline />
          <LayoutCard>
            <div className="flex flex-col justify-center gap-8">
              <div className="flex h-[30px] flex-col items-start justify-center gap-2">
                <div className="text-color-neutral-strong text-2xl font-semibold leading-[30px]">
                  {Strings.PAYMENT}
                </div>
              </div>
              <p className="items-start text-lg">
                {Strings.SELECT_A_PAYMENT_METHOD}
              </p>
              <SelectPaymentMethods
                data={allowedPaymentMethods}
                selectedMethod={selectedMethod}
                handlePaymentMethodChange={handlePaymentMethodChange}
              />
            </div>
            <div className="flex justify-center">
              <Button
                isLoading={isPending}
                text={Strings.CONTINUE}
                icon={Icons.ArrowRightAlt}
                onClick={handleContinue}
              />
            </div>
          </LayoutCard>
        </div>
        <OrderSummaryCard order={order} />
      </div>
    </main>
  );
};

export default PaymentMethods;
