import React, { ChangeEvent } from 'react';

import { Strings } from 'src/constants';

type SelectInstallmentsProps = {
  quantity: number;
  value: number;
  error?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const SelectInstallments: React.FC<SelectInstallmentsProps> = ({
  quantity,
  value,
  error,
  disabled = false,
  onChange,
  onFocus,
  ...rest
}) => {
  return (
    <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
      <div className="text-color-neutral-base-5 text-sm font-normal leading-[21px]">
        {Strings.NUMBER_OF_PLOTS}
      </div>
      <select
        className="w-full bg-color-neutral-base-2 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        {...rest}
      >
        {[...Array(quantity)].map((_, index) => {
          const newIndex = index + 1;
          const normalizeText = newIndex === 1 ? 'parcela' : 'parcelas';
          const description = `${newIndex} ${normalizeText} (sem juros)`;

          return (
            <option key={`installment-${newIndex}`} value={newIndex}>
              {description}
            </option>
          );
        })}
      </select>
      {error && (
        <div className="self-stretch text-color-primary-base text-sm font-normal leading-[21px]">
          {error}
        </div>
      )}
    </div>
  );
};

export default SelectInstallments;
