import { Strings } from "src/constants";
import { CurrencyHelper } from "src/utils";

const PaymentMethodInfo: React.FC<{ method?: string; amount: number }> = ({
  method,
  amount,
}) => {
  const methodName = method ? Strings[method as keyof typeof Strings] : "";
  return (
    <div
      className={`flex w-full justify-between rounded-lg p-4 font-light ${method === "CREDIT_CARD" ? "bg-color-neutral-base-2" : "bg-color-neutral-soft px-0"}`}
    >
      <p>{methodName}</p>
      <p>{CurrencyHelper.formatBRCurrency(amount)}</p>
    </div>
  );
};

export default PaymentMethodInfo;
