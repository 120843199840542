import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { payOrderCheckout, RequestInputType } from "src/services";

const usePayOrderCheckout = () => (
    useMutation<
        PixCheckoutResponse | BankSlipCheckoutResponse,
        AxiosError<ErrorResponse>,
        RequestInputType
    >({
        mutationFn: ({ token, request }) => payOrderCheckout({ token, request }),
        onError: (error) => {
            console.log(error);
        },
    })
);

export default usePayOrderCheckout;