import React from "react";

import "./styles.css";

interface Props {
  width?: string;
  height?: string;
  border?: string;
}

const ButtonSpinner: React.FC<Props> = ({
  width = "50px",
  height = "50px",
  border = "3px solid #FFFFFF",
}) => {
  return (
    <div
      className="border-3 border-color-primary-base"
      style={{
        width: width,
        height: height,
        border: border,
        borderTop: "3px solid #A21426",
        borderRadius: "50%",
        animation: "spinner 1.5s linear infinite",
      }}
    />
  );
};

export default ButtonSpinner;
