import Section from "../Section";
import { Button } from "src/components";

import { Icons, Strings } from "src/constants";
import { CurrencyHelper } from "src/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";
import { useState } from "react";

const OrderCard: React.FC = () => {
  const data = useCheckoutDataContext();
  const navigate = useNavigate();
  const params = useParams<OrderSubscriptionParamsType>();
  const token = params.token as string;
  const [isLoading, setIsLoading] = useState(false);
  const licensePlateList = data?.insurance?.policyInformation?.cars.map(
    (car) => car.licensePlate,
  );

  const handleStartPayment = () => {
    setIsLoading(true);
    navigate(`/${token}/PaymentMethods`, {
      state: {
        order: data,
      },
    });
  };

  return (
    <div className="flex h-auto w-full flex-col gap-8 rounded-2xl border border-black/0 bg-color-neutral-soft p-8 shadow lg:w-[676px]">
      <div className="flex flex-col items-center justify-center gap-8 self-stretch">
        <div className="flex flex-col items-start justify-center gap-2 self-stretch">
          <div className="text-color-neutral-strong text-2xl font-semibold leading-[30px]">
            {Strings.CAR_INSURANCE}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 self-stretch rounded-lg">
          <Section
            label={Strings.TITULAR}
            icon={Icons.AccountCircle}
            data={data?.customer?.fullName}
          />
          <Section
            label={Strings.CPF}
            icon={Icons.Badge}
            data={data?.customer.document}
          />
          <Section
            label={Strings.CONTRACTED_VALUE}
            icon={Icons.MonetizationOn}
            data={CurrencyHelper.formatBRCurrency(
              data?.checkoutPreferences?.totalAmount ?? 0,
            )}
          />
          <Section
            label={Strings.BROKER}
            icon={Icons.Broker}
            data={data?.merchant?.fantasyName}
          />
          <Section
            label={Strings.PROPOSAL_NUMBER}
            icon={Icons.Article}
            data={data?.insurance?.policyNumber}
          />
          <Section
            label={Strings.VEHICLE_LICENSE_PLATE}
            icon={Icons.Vehicle}
            data={licensePlateList}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          text={Strings.START_PAYMENT}
          icon={Icons.ArrowRightAlt}
          onClick={handleStartPayment}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default OrderCard;
