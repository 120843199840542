import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "src/components";
import { useCheckout } from "src/hooks";

const SharedDataContext = createContext<CheckoutType | undefined>(undefined);

export const useCheckoutDataContext = () => {
  const context = useContext(SharedDataContext);
  if (!context) {
    throw new Error("Error in loading the context");
  }
  return context;
};

export const SharedDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const params = useParams<OrderSubscriptionParamsType>();
  const token = params.token as string;

  const { data, isFetching, error } = useCheckout(token);

  if (isFetching) return <Spinner />;
  if (error) return <div>Error: {(error as Error).message}</div>;

  return (
    <SharedDataContext.Provider value={data}>
      {children}
    </SharedDataContext.Provider>
  );
};
