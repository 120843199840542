import React from 'react';
import { Icons } from 'src/constants';

const CreditCardWarning: React.FC = () => {
  return (
    <div className="p-4 bg-color-status-danger-soft rounded-lg border-color-status-danger-base justify-start items-center gap-4 inline-flex">
      <img src={Icons.Error} alt="Erro" />
      <div className="grow shrink">
        <span className="text-color-status-danger-strong text-base font-bold leading-normal">
          O pagamento não foi realizado.
        </span>
        <span className="text-color-status-danger-strong text-base font-normal leading-normal">
          Por favor, verifique os dados do cartão e tente novamente.
        </span>
      </div>
    </div>
  );
};

export default CreditCardWarning;
