import React from "react";
import useTimer from "src/hooks/useTimer";
import { TimeHelper } from "src/utils";

export const TimerComponent = () => {
  const time = useTimer({
    initialTime: 300, // 10 minutes (600 seconds)
    onEnd: () => console.log("Timer finished!"),
  });
  return (
    <p className="items-start font-bold text-color-status-info-text">
      {TimeHelper.formatTime(time)}
    </p>
  );
};
