import { CurrencyHelper } from "src/utils";

const formatTotalAmount = (totalAmount: number) => {
  return CurrencyHelper.formatBRCurrency(totalAmount);
};

const TotalAmount: React.FC<{ total: number }> = ({ total }) => (
  <div className="inline-flex items-center justify-center gap-4 self-stretch py-2">
    <div className="shrink grow basis-0 text-base font-bold leading-normal text-color-neutral-base-5">
      Total
    </div>
    <div className="text-color-neutral-strong text-base font-bold leading-normal">
      {formatTotalAmount(total)}
    </div>
  </div>
);

export default TotalAmount;
