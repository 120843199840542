const renderData = <T,>(data: T) => {
  if (Array.isArray(data)) {
    return data.map((item, index) => {
      return (
        <div
          className="text-color-neutral-strong self-stretch text-base font-normal leading-normal"
          key={index}
        >
          <div>{item}</div>
        </div>
      );
    });
  }

  return (
    <div className="text-color-neutral-strong self-stretch text-base font-normal leading-normal">
      {data as string}
    </div>
  );
};

type SectionProps<T> = {
  label: string;
  icon: string;
  data?: T | T[];
};

const Section = <T,>({ label, icon, data }: SectionProps<T>) => {
  return (
    <div className="flex h-[85px] flex-col items-center justify-center gap-2 self-stretch">
      <div className="flex h-[85px] flex-col items-center justify-center gap-2 self-stretch">
        <div className="inline-flex items-center justify-start gap-4 self-stretch bg-black/0 py-2">
          <div className="relative h-6 w-6">
            <img src={icon} alt="" />
          </div>
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start">
            <div className="self-stretch text-sm font-normal leading-[21px] text-color-neutral-base-5">
              {label}
            </div>
            {renderData(data)}
          </div>
        </div>
        <div className="inline-flex items-start justify-start gap-2 self-stretch py-2">
          <div className="h-[0px] shrink grow basis-0 border border-color-neutral-base-2 bg-color-neutral-base-2"></div>
        </div>
      </div>
    </div>
  );
};

export default Section;
