const ProductInfo: React.FC<{
  description?: string;
  productTitle?: string;
}> = ({ description, productTitle }) => (
  <div className="inline-flex items-center justify-center gap-8 self-stretch py-2">
    <div className="shrink grow basis-0 text-base font-normal leading-normal text-color-neutral-base-5">
      {productTitle}
    </div>
    <div className="text-color-neutral-strong text-base font-normal leading-normal">
      {description}
    </div>
  </div>
);

export default ProductInfo;
