import React from "react";

const LayoutCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex w-full items-start justify-center gap-4 md:min-w-[612px]">
      <div className="flex h-auto w-full flex-col justify-center gap-3 rounded-2xl border border-black/0 bg-color-neutral-soft p-8 shadow md:gap-8">
        {children}
      </div>
    </div>
  );
};

export default React.memo(LayoutCard);
