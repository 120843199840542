import { Routes } from '.';
import { Icons } from '.';
import { Strings } from '.';



export const ApiHeaderKeys = {
  ACCEPT_KEY: 'accept',
  ACCEPT_VALUE: 'application/json, text/plain, */*',
  CONTENT_TYPE_KEY: 'Content-Type',
  CONTENT_TYPE_VALUE: 'application/json',
};

export const PaymentMethodKeys = [

  {
    type: 'CREDIT_CARD',
    icon: Icons.CreditCard,
    label: Strings.CREDIT_CARD,
    description: Strings.EMPTY,
    route: Routes.CREDIT_CARD,
  },
  {
    type: 'PIX',
    icon: Icons.Pix,
    label: Strings.PIX,
    description: Strings.EMPTY,
    route: Routes.PIX,
  },
  {
    type: 'BANK_SLIP',
    icon: Icons.Barcode,
    label: Strings.BANK_SLIP,
    description: Strings.EMPTY,
    route: Routes.BANK_SLIP,
  },
  // {
  //   type: 'BALANCE',
  //   icon: Icons.CreditCard,
  //   label: Strings.BALANCE,
  //   description: Strings.EMPTY,
  //   route: Routes.CREDIT_CARD,
  // },
  // {
  //   type: 'LOYALTY_POINTS',
  //   icon: Icons.CreditCard,
  //   label: Strings.LOYALTY_POINTS,
  //   description: Strings.EMPTY,
  //   route: Routes.CREDIT_CARD,
  // },
  // {
  //   type: 'OPEN_FINANCE',
  //   icon: Icons.CreditCard,
  //   label: Strings.OPEN_FINANCE,
  //   description: Strings.EMPTY,
  //   route: Routes.CREDIT_CARD,
  // },
  // {
  //   type: 'DIRECT_DEBIT',
  //   icon: Icons.Debit,
  //   label: Strings.DEBIT,
  //   description: Strings.CASH_PAYMENT,
  //   route: Routes.CREDIT_CARD,
  // },
  // {
  //   type: 'NUPAY',
  //   icon: Icons.NuPay,
  //   label: Strings.NUPAY,
  //   description: Strings.EMPTY,
  //   route: Routes.CREDIT_CARD,
  // },

  // {
  //   type: 'GIFT_CARD_VTEX',
  //   icon: Icons.Barcode,
  //   label: Strings.BANK_SLIP,
  //   description: Strings.EMPTY,
  //   route: Routes.BANK_SLIP,
  // },
];

export const CreditCardKeys = {
  CARD_NUMBER: 'cardNumber',
  HOLDER: 'nameOnCard',
  EXPIRATION_DATE: 'expirationDate',
  SECURITY_CODE: 'cardKey',
  DOCUMENT: 'document',
  DATE_OF_BIRTH: 'dateOfBirth',
  INSTALLMENTS: 'installments',
};
