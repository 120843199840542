import { StringHelper, CreditCardHelper } from 'src/utils';

import { KeyValues, Strings } from 'src/constants';

const { CreditCardKeys } = KeyValues;
type CreditCardValidationProps = {
  inputs: {
    [x: string]: string | number;
  };
  handleError: (error: string, inputName: string) => void;
};

const CreditCardValidation = ({
  inputs,
  handleError,
}: CreditCardValidationProps) => {
  let isValidForm = true;

  const holder = inputs[CreditCardKeys.HOLDER] as string;
  const document = inputs[CreditCardKeys.DOCUMENT] as string;
  const cardNumber = inputs[CreditCardKeys.CARD_NUMBER] as string;
  const dateOfBirth = inputs[CreditCardKeys.DATE_OF_BIRTH] as string;
  const securityCode = inputs[CreditCardKeys.SECURITY_CODE] as string;
  const expirationDate = inputs[CreditCardKeys.EXPIRATION_DATE] as string;
  const installments = inputs[CreditCardKeys.INSTALLMENTS] as number;

  if (StringHelper.isNullOrEmpty(cardNumber)) {
    handleError(Strings.REQUIRED, CreditCardKeys.CARD_NUMBER);
    isValidForm = false;
  } else if (!CreditCardHelper.validateCardNumber(cardNumber)) {
    handleError(Strings.INVALID_CARD_NUMBER, CreditCardKeys.CARD_NUMBER);
    isValidForm = false;
  }

  if (StringHelper.isNullOrEmpty(holder)) {
    handleError(Strings.REQUIRED, CreditCardKeys.HOLDER);
    isValidForm = false;
  } else if (!CreditCardHelper.validateHolder(holder)) {
    handleError(Strings.INVALID_HOLDER, CreditCardKeys.HOLDER);
    isValidForm = false;
  }

  if (StringHelper.isNullOrEmpty(document)) {
    handleError(Strings.REQUIRED, CreditCardKeys.DOCUMENT);
    isValidForm = false;
  } else if (!CreditCardHelper.validateDocument(document)) {
    handleError(Strings.INVALID_DOCUMENT, CreditCardKeys.DOCUMENT);
    isValidForm = false;
  }

  if (StringHelper.isNullOrEmpty(dateOfBirth)) {
    handleError(Strings.REQUIRED, CreditCardKeys.DATE_OF_BIRTH);
    isValidForm = false;
  } else if (!CreditCardHelper.isOver18(dateOfBirth)) {
    handleError(Strings.INVALID_DATE_OF_BIRTH, CreditCardKeys.DATE_OF_BIRTH);
    isValidForm = false;
  }

  if (StringHelper.isNullOrEmpty(securityCode)) {
    handleError(Strings.REQUIRED, CreditCardKeys.SECURITY_CODE);
    isValidForm = false;
  } else if (!CreditCardHelper.validateSecurityCode(securityCode)) {
    handleError(Strings.INVALID_SECURITY_CODE, CreditCardKeys.SECURITY_CODE);
    isValidForm = false;
  }

  if (StringHelper.isNullOrEmpty(installments)) {
    handleError(Strings.REQUIRED, CreditCardKeys.INSTALLMENTS);
    isValidForm = false;
  }

  if (StringHelper.isNullOrEmpty(expirationDate)) {
    handleError(Strings.REQUIRED, CreditCardKeys.EXPIRATION_DATE);
    isValidForm = false;
  } else if (!CreditCardHelper.validateExpirationDate(expirationDate)) {
    handleError(
      Strings.INVALID_EXPIRATION_DATE,
      CreditCardKeys.EXPIRATION_DATE,
    );
    isValidForm = false;
  }

  return isValidForm;
};

export default CreditCardValidation;
