import { useQuery } from '@tanstack/react-query';

import { checkout } from 'src/services';

const useCheckout = (token: string) => {
  const { data, isFetching, error } = useQuery({
    queryKey: ['checkout', token],
    queryFn: () => checkout(token),
    enabled: !!token,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return { data, isFetching, error };
};

export default useCheckout;
