import React from 'react';

import { Strings } from 'src/constants';

const PageNotFound: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '64px 32px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <article className="cols__col cols__col--vertical-center">
        <h2>{Strings.PAGE_NOT_FOUND}</h2>
      </article>
    </div>
  );
};

export default PageNotFound;
